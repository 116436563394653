import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppStateService } from '@app/shared/services/app-state.service';
import { MakeAChangeActivityComponent } from '@app/pages/activities';
import { CORRECT, INCORRECT } from '@app/shared/constants/activity-constants';

@Component({
  selector: 'app-letter-panel-component',
  templateUrl: './letter-panel.component.html',
  styleUrls: ['./letter-panel.component.scss'],
  standalone: true,
  imports: [CommonModule],
  // currently unused fade animation - check the HTML to enable this
  animations: [
    trigger('fadeOut', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', [animate('1s')]),
      transition('hidden => visible', [animate('.2s')]),
    ]),
  ],
})
export class LetterPanelComponent implements OnInit {
  @Input() letterPanel: ILetterPanel;
  @Input() isChoice = false;
  @Input() text?: boolean;
  @Output() chosenPanelSuffix = new EventEmitter<any>();
  @Output() chosenPanel = new EventEmitter<any>();

  @Input() showTopBorder = false;
  @Input() showBottomBorder = false;
  @Input() showLeftBorder = false;
  @Input() showRightBorder = false;

  @ViewChild('letter') letter!: ElementRef;

  panelColorMap = {
    suffix: '#F9E344',
    gluedSound: '#C1D8AC',
    vowel: '#EDC7BC',
    default: '#FAE79A',
    whitebox: '#FFFFFF',
  };
  panelColor = '';

  panelStatus = '';
  CORRECT = CORRECT;
  INCORRECT = INCORRECT;

  constructor() {}

  ngOnInit() {
    if (this.letterPanel?.content) {
      this.letterPanel.id = AppHelpers.generateId();
      if(!this.text)this.generateBgColors(this.letterPanel);
      if (this.letterPanel.hidden === undefined) this.isHidden(this.letterPanel);
    }
  }

  setFocus(hide = false) {
    if (hide) {
      this.letter.nativeElement.blur();
    } else {
      this.letter.nativeElement.focus();
    }
  }

  resetPanelStates() {
    if (this.letterPanel) {
      this.letterPanel.error = false;
      this.letterPanel.success = false;
      this.letterPanel.active = false;
    }
  }

  // todo: these are generated upfront. we need a way to change it at will.
  generateBgColors(panel: ILetterPanel) {
    Object.keys(panel)
      .filter((p) => Object.keys(this.panelColorMap).includes(p))
      .forEach((key) => {
        if (panel.isWhiteBox) {
          this.panelColor = this.panelColorMap.whitebox;
        } else if (panel[key] === true) {
          this.panelColor = this.panelColorMap[key];
        }
      });
  }

  handleClick() {
    this.chosenPanel.emit(this.letterPanel);
    this.chosenPanelSuffix.emit(this.letterPanel);
  }

  displayMark(status: string) {
    this.panelStatus = status;
  }

  clearMark() {
    this.panelStatus = '';
  }

  isHidden(panel: ILetterPanel) {
    if (panel.isChoice) {
      this.letterPanel.hidden = false;
    } else if (!panel.isChoice && !panel.complete) {
      this.letterPanel.hidden = true;
    } else if (!panel.isChoice && panel.complete) {
      this.letterPanel.hidden = false;
    }
  }
}
