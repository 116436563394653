<div id="wlt-activity" class="wlt-activity" *ngIf="this.activityReady">
  <div class="game-area">
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Blank">
      <app-mac-puzzle-type-0 [currentMacDataset]="currentMacDataset" [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete()" [newGroup]="newGroup" (subPartComplete)="incrementProgress($event)">
      </app-mac-puzzle-type-0>
    </ng-container>

    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Basic">
      <app-mac-puzzle-type-1 [currentMacDataset]="currentMacDataset" [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete()" [newGroup]="newGroup" (subPartComplete)="incrementProgress($event)">
      </app-mac-puzzle-type-1>
    </ng-container>

    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Bank">
      <app-mac-puzzle-type-2 [currentMacDataset]="currentMacDataset" [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete()" [newGroup]="newGroup" (subPartComplete)="incrementProgress($event)">
      </app-mac-puzzle-type-2>
    </ng-container>
  </div>

  <div class="progress-bar-area" [ngClass]="{
    visible: !activitySolved && displayPB,
    hidden: activitySolved || !displayPB
  }">
  <app-progress-bar
  [totalPuzzles]="totalPuzzles"
  [answeredPuzzles]="answeredPuzzles">
  </app-progress-bar>
</div>
</div>