import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlaceholderComponent } from '@app/shared/components/placeholder/placeholder.component';
import { GlobalErrorHandler } from './shared/helpers/error-handler';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AudioSidebarComponent } from './shared/components/audio-sidebar/audio-sidebar.component';
import { PrimaryLayoutComponent } from './layouts/primary-layout/primary-layout.component';
import { UIModule } from './ui.module';
import { SanitizeUrlPipeModule } from './pipes/sanitize-url/sanitize-url-pipe.module';
import { ActivityLayoutComponent } from '@app/layouts/activity-layout/activity-layout.component';
import { ActivityHeaderComponent } from '@app/pages/activities/activity-header/activity-header.component';
import { NarratorComponent } from './shared/components/narrator/narrator.component';
import { DebugConsoleComponent } from './shared/components/debug-console-panel/debug-console.component';
import { HttpInterceptorProvider } from './shared/interceptors/app.interceptors';
import { ScorePanelComponent } from './shared/components/score-panel/score-panel.component';
import { PuzzleChooserComponent } from './shared/components/puzzle-chooser/puzzle-chooser.component';
import { TrackingModule } from './shared/directives/tracking.module';
import { ExpressionViewerComponent } from './shared/components/expression-viewer/expression-viewer.component';
import { MicAccessPopupComponent } from './shared/components/mic-access-popup/mic-access-popup.component';

@NgModule({
  declarations: [AppComponent, PlaceholderComponent, PrimaryLayoutComponent, ActivityLayoutComponent, ActivityHeaderComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    MessageService,
    ConfirmationService,
    HttpInterceptorProvider,
  ],
  bootstrap: [AppComponent],
  imports: [
    UIModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SanitizeUrlPipeModule,
    TrackingModule,
    AudioSidebarComponent,
    PuzzleChooserComponent,
    NarratorComponent,
    DebugConsoleComponent,
    ScorePanelComponent,
    ExpressionViewerComponent,
    MicAccessPopupComponent
  ],
})
export class AppModule { }
