import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, DestroyRef, inject } from '@angular/core';
import { IWordFindFunDataset } from '../../types/word-find-fun-dataset-interface';
import { ICard } from '@app/shared/components/card/types/card-interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { MISTAKE_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { StartButtonComponent } from '@app/shared/components/start-button/start-button.component';
import { CardRotatorComponent } from '@app/shared/components/carousel/card-rotator.component';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { LEFT_BRACKET, LEFT_PARENTHESES, RIGHT_BRACKET, RIGHT_PARENTHESES } from '@app/shared/constants/activity-constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-wff-puzzle-type-1',
  templateUrl: './puzzle-type-1.component.html',
  styleUrls: ['./puzzle-type-1.component.scss'],
  imports: [CommonModule, CardRotatorComponent, StartButtonComponent],
  standalone: true,
  providers: [],
})
export class WFFPuzzleType1Component extends PuzzleTypeBaseComponent {
  @Input() currentWordFindFunDataset: IWordFindFunDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() activitySolved: boolean;
  @Input() puzzleTypeEnum: any;
  @Input() wordFindFunDatasets: IWordFindFunDataset[];
  @Output() completedPuzzle = new EventEmitter();
  @Output() carouselProceedChange = new EventEmitter<boolean>();
  @Output() displayPB = new EventEmitter();

  //   protected wordFindFunDatasets: IWordFindFunDataset[];
  protected correctCards: ICard[] = [];
  protected correctWords: ICard[] = [];
  protected wordCards: ICard[] = [];
  protected wordCardsCarousel: ICard[] = [];
  carouselCompleted = false;
  carouselProceed = false;
  protected completedWords: Set<string> = new Set<string>();
  protected isRecordingEnabled: boolean = false;

  private readonly _answer_prefix = 'answer-';
  private _validatingPuzzle = false;
  private _destroyRef = inject(DestroyRef);
  

  expressionMap = {
    puzzleInstructions: 1,
    success: 2,
    failure: 3,
    failure2: 4,
    carouselInstructions: 5,
  };

  constructor() {
    super();

    // Subscribe to the recording state from AppStateService
    this.appStateService.isRecordingEnabled$
      .pipe(takeUntilDestroyed(this._destroyRef)) 
      .subscribe((isRecordingEnabled: boolean) => {
        this.isRecordingEnabled = isRecordingEnabled;
        this.expressionMap.carouselInstructions = isRecordingEnabled ? 5.1 : 5;
      });
  }

  initPuzzle() {
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentWordFindFunDataset?.maxTries)
    this.startPuzzleMetrics();
    this.buildCarousel();
    if (this.getExpression(this.expressionMap.carouselInstructions)) {
      this.carouselProceed = false;
      this.carouselCompleted = false;
      this.soundService.playExpression(this.getExpression(this.expressionMap.carouselInstructions), () => {

      });
      this.appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PUZZLE_CLUE,
        puzzleClueExpression: this.getExpression(this.expressionMap.carouselInstructions) || '',
      });
    } else {
      this.soundService.playExpression(this.getExpression(1))
      this.appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PUZZLE_CLUE,
        puzzleClueExpression: this.getExpression(1) || '',
      });
    }
  }

  buildCarousel() {
    this.wordCards = [];
    this.wordCardsCarousel = [];
    this.correctCards = [];
    this.completedWords = new Set<string>(); // Initialize the set here to ensure it's reset for each carousel build

    // Ensure WordFindFunDatasets is defined and is an array
    if (!Array.isArray(this.wordFindFunDatasets)) {
      return;
    }

    // Gather correct words from completed puzzles of the same type
    const completedPuzzles = this.wordFindFunDatasets.filter((d) => d.completed && d.puzzleType === this.puzzleTypeEnum.Word);

    completedPuzzles.forEach((puzzle) => {
      if (typeof puzzle.choices !== 'string') {
        return;
      }

      const correctWords = puzzle.choices
        .trimAll()
        .clean(LEFT_PARENTHESES)
        .clean(RIGHT_PARENTHESES)
        .split(',')
        .filter((word) => word.includes(LEFT_BRACKET))
        .map(
          (word) =>
          ({
            id: AppHelpers.generateId(),
            content: word.clean(LEFT_BRACKET).clean(RIGHT_BRACKET),
            complete: true,
          } as ICard)
        );
      this.correctWords.push(...correctWords);
      correctWords.forEach((word) => this.completedWords.add(word.content)); // Add words to the completedWords set
    });

    if (typeof this.currentWordFindFunDataset?.choices !== 'string') {
      return;
    }

    const choices = this.currentWordFindFunDataset.choices.trimAll().clean(LEFT_PARENTHESES).clean(RIGHT_PARENTHESES).split(',');

    // Shuffle content
    choices.sort(() => 0.5 - Math.random());

    choices.forEach((c, idx) => {
      const card: ICard = {
        id: AppHelpers.generateId(),
        index: idx,
        content: c.clean(LEFT_BRACKET).clean(RIGHT_BRACKET),
        active: false,
        complete: false, // Initialize complete status to false
      };

      this.wordCards.push(card);

      if (c.includes(LEFT_BRACKET)) {
        const newCard = Object.assign({}, card, { error: false });
        this.correctCards.push(newCard);
        this.correctWords.push(newCard);
      }

      // Check to see if the word is in the completed set and if it's needed for the current puzzle
      const isCompleted = this.completedWords.has(card.content);
      const isNeeded = this.correctCards.some((correctCard) => correctCard.content === card.content);

      if (isCompleted) {
        card.complete = true; // Mark card as complete if it is in the completed set
      }

      if (!isCompleted || isNeeded) {
        this.wordCardsCarousel.push(card);
        if (isNeeded) {
          card.complete = false; // Ensure needed cards are marked as not complete
        }
      }
    });

    this.resetPuzzle();
    AnimationHelpers.animate(`answers`, ANIMATIONS.BackInDown);
    AnimationHelpers.animate(`choices`, ANIMATIONS.BackInDown);
  }

  onPlayWord(word: string) {
    this.soundService.playExpression(word);
  }

  onCardClicked(card: ICard) {
    if (card.complete || this._validatingPuzzle) {
      return;
    }

    this._validatingPuzzle = true;

    this.wordCards.filter((c) => !c.complete).map((c) => (c.active = false));
    card.active = true;
    AnimationHelpers.animate(card.id, ANIMATIONS.Pulse);
    this.soundService.playExpression(card.content, () => {
      setTimeout(() => {
        this.validatePuzzle(card);
      }, DELAY.S1);
    });
  }

  validatePuzzle(card: ICard) {
    let autoSolved = false;
    const isCorrect = this.currentWordFindFunDataset?.choices.includes(`[${card.content}]`);

    if (!isCorrect) {
      card.error = true;
      AnimationHelpers.animate(card.id, ANIMATIONS.ShakeX);
      this.soundService.playSound(SOUND_NAMES.Incorrect);

      this.addUserMistakeDetails({
        content: card.content,
        context: MISTAKE_CONTEXT.Wrong_Word,
      });

      if (this.hasTriesExceeded()) {
        autoSolved = true;
        this.addMistake();
      } else {
        setTimeout(() => {
          this.soundService.playExpression(this.getExpression(3), () => {
            card.error = false;
            card.active = false;
            this._validatingPuzzle = false;
          });
        }, DELAY.S1);
      }
    } else {
      card.complete = true;
      card.semiTrans = true;
      card.active = true;
      this.soundService.playSound(SOUND_NAMES.Correct);
      const emptySlotCard = this.correctCards.find((c) => !c.complete);

      this.anim.animateTo(card.id, `${this._answer_prefix}${emptySlotCard?.id}`, () => {
        this.insertCardToEmptySlot(card.id);
        const isPuzzleSolved = !this.correctCards.find((c) => c.complete !== true);

        if (isPuzzleSolved) {
          this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: 'burst-area-wff' }, BURST_TYPE.Round);
          setTimeout(() => {
            this.processPuzzleSolved(false);
          }, DELAY.S1);
        } else {
          this._validatingPuzzle = false;
        }
      });
    }

    if (autoSolved) {
      setTimeout(() =>{
        this.processPuzzleSolved(true);
      }, DELAY.S1)
    }
  }

  processPuzzleSolved(autoSolved: boolean) {
    if (autoSolved) {
      this.soundService.playExpression(this.getExpression(4), () => {
        this.readCorrectCards(() => {
         setTimeout(() =>{
            this.endPuzzleMetrics(PUZZLE_STATUS.FAIL);
            this.resetPuzzle();
            this.completedPuzzle.emit({passed: this._selfCorrected});
          }, DELAY.S2)
        });
      });
    } else {
      this.endPuzzleMetrics(PUZZLE_STATUS.PASS);
      this.completedPuzzle.emit({passed: !this._selfCorrected});
    }
  }

  resetPuzzle() {
    this._validatingPuzzle = false;
    this.resetTries();

    this.correctCards.map((c) => (c.complete = false));
    this.wordCards.forEach((c) => {
      c.complete = false;
      c.active = false;
      c.error = false;
    });
  }

  readCorrectCards(callback?: () => void | undefined) {
    this.wordCards.map((c) => (c.error = false));
    const int = setInterval(() => {
      const emptyCards = this.correctCards.filter((c) => !c.complete);

      const emptyCard = emptyCards.find((c) => !c.complete) || undefined;
      const answerCard = this.wordCards.find((c) => c.id === emptyCard?.id);

      this.soundService.playSound(SOUND_NAMES.Correct);
      this.onPlayWord(answerCard?.content || '');

      this.jumpCardToCard(answerCard?.id || '', `${this._answer_prefix}${emptyCard?.id}`, () => {
        if (emptyCard) {
          emptyCard.content = answerCard?.content || '';
          emptyCard.complete = true;
        }
        const finalEmptyCard = emptyCards.find((c) => !c.complete) || undefined;
        if (!finalEmptyCard) {
          clearInterval(int);
          callback ? callback() : undefined;
        }
      });
    }, DELAY.S2);
  }

  jumpCardToCard(card1Id: string, card2Id: string, callback?: () => void | undefined) {
    this.anim.animateTo(card1Id, card2Id, () => {
      callback ? callback() : undefined;
    });
  }

  insertCardToEmptySlot(cardId: string | undefined) {
    const card = this.correctCards.find((c) => c.id === cardId);
    const cardIdx = this.correctCards.findIndex((c) => c.id === cardId);
    const freeSlot = this.correctCards.findIndex((c) => !c.complete);

    if (card) {
      const tempCard = this.correctCards[freeSlot];
      this.correctCards[freeSlot] = this.correctCards[cardIdx];
      this.correctCards[cardIdx] = tempCard;
      card.complete = true;

      setTimeout(() => {
        const id = `${this._answer_prefix}${card?.id}`;
        AnimationHelpers.animate(id, ANIMATIONS.Bounce);
      }, DELAY.SMALL);
    }
  }

  onCarouselProceedClick() {
    this.carouselProceed = true;
    this.wordCards.forEach((c) => (c.active = false));
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });
    this.displayPB.emit();
    this.soundService.playExpression(this.getExpression(1), () => {
      this.appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PUZZLE_CLUE,
        puzzleClueExpression: this.getExpression(1) || '',
      });
      AnimationHelpers.animate(`choices`, ANIMATIONS.Pulse);
    });
    this.carouselProceedChange.emit(true);
  }

  onCarouselCompleted() {
    this.carouselCompleted = true;
    this.currentWordFindFunDataset = this.wordFindFunDatasets?.find((w) => !w.completed && w.puzzleType === this.puzzleTypeEnum.Word);
    this.startPuzzleMetrics();
    setTimeout(() => {
      AnimationHelpers.animate('play-button', ANIMATIONS.Pulse, DELAY.S3);
    }, DELAY.S3);
  }
}
