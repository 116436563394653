<p-sidebar
  #sidebarRef
  [(visible)]="sidebarVisible"
  position="bottom"
  [modal]="false"
  [baseZIndex]="9999"
  [showCloseIcon]="false"
  class="custom-sidebar"
  styleClass="h-13rem"
  [style]="{ backgroundColor: '#EBF2F8' }"
  *ngIf="appEvent$ | async as appEvent"
>
  <ng-template pTemplate="headless">
    <div class="sidebar-content"  [ngClass]="{'overflow-y-scroll': messages.length > 1}">
      <div class="sidebar-header">
        <h3 class="sidebar-title">Missing Audio</h3>
        <div class="buttons flex align-items-center justify-content-end gap-3">
          <p-toggleButton [(ngModel)]="showExp" onLabel="Text On" offLabel="Text Off" (onChange)="invokeCallback($event)" styleClass="h-1rem w-7rem"/>
          <p-button type="button" icon="pi pi-copy" [cdkCopyToClipboard]="copyData" styleClass="h-1rem w-2rem"></p-button>
          <p-button type="button" (click)="closeSideBar($event)" icon="pi pi-times" styleClass="h-1rem w-2rem"></p-button>
        </div>
      </div>
      <hr class="separator" />
      <div class="p-grid p-nogutter sidebar-grid">
        <div class="col-4 column expression">
          <h4>Expression</h4>
          <li *ngFor="let msg of messages; let i = index" class="expression-item">
            <p>{{msg.expression}}</p>
          </li>
        </div>
  
        <div class="col-4 column filename">
          <h4>File Name</h4>
          <li *ngFor="let msg of messages; let i = index" class="expression-item">
            <p>{{msg.filename}}</p>
          </li>
        </div>
  
        <!-- <div class="col-4 column confirm">
          <h4>Confirm</h4>
          <li *ngFor="let msg of messages; let i = index" class="expression-item">
            <button *ngIf="i === 0 && buttonVisible && !showExp" (click)="onDialogHidden(msg.callback)" class="p-button">Confirm</button>
          </li>
        </div> -->
      </div>
    </div>
  </ng-template>
</p-sidebar>