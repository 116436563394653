import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AudioRecordingService {
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private isAnalyzing: boolean = true;
  public audioData$ = new Subject<Uint8Array>();


  recordAudio(): Promise<{ 
    start: () => void; 
    stop: () => Promise<{ audioBlob: Blob; audioUrl: string; play: () => void }> 
    pause: () => void;
    resume: () => void;
  }> {
    return new Promise((resolve) => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: BlobPart[] = [];

        mediaRecorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data);
        });

        this.audioContext = new AudioContext();
        const source = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 2048;
        source.connect(this.analyser);
        const dataArray = new Uint8Array(this.analyser.frequencyBinCount);

        // Function to emit frequency data
        const updateFrequencyData = () => {
          if (this.analyser && this.isAnalyzing) {
            this.analyser.getByteFrequencyData(dataArray);
            this.audioData$.next(dataArray); 
          }
          requestAnimationFrame(updateFrequencyData); 
        };

        const start = () => {
          mediaRecorder.start();
          this.isAnalyzing = true;
          updateFrequencyData();
        };

        const stop = (): Promise<{ audioBlob: Blob; audioUrl: string; play: () => void }> => {
          return new Promise((resolve) => {
            mediaRecorder.addEventListener('stop', () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg-3' });
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);

              // play is only used for testing
              const play = () => {
                audio.play();
              };

              resolve({ audioBlob, audioUrl, play });
            });

            if (this.audioContext) {
              this.audioContext.close();
              this.audioContext = null;
              this.analyser = null;
            }

            mediaRecorder.stop();
            this.isAnalyzing = false;
          });
        };

        const pause = () => {
          if (mediaRecorder.state === 'recording') {
            mediaRecorder.pause();
            this.isAnalyzing = false;
          }
        };

        const resume = () => {
          if (mediaRecorder.state === 'paused') {
            mediaRecorder.resume();
            this.isAnalyzing = true;
            updateFrequencyData();
          }
        };

        resolve({ start, stop, pause, resume });
      });
    });
  }
}
