<div class="login-page container page-content">
  <button *ngIf="currentStep > loginSteps.SELECTION" class="back-btn" (click)="stepBack()">
    <img src="assets/back-arrow.svg" alt="back arrow" class="back-btn__arrow" />
    <img src="assets/back-arrow.svg" alt="back arrow" class="back-btn__arrow arrow-2" />
    <span>Back</span>
  </button>
  <div class="login-page__steps-container">
    <ng-container [ngSwitch]="currentStep">
      <ng-container *ngSwitchCase="loginSteps.SELECTION">
        <div class="login-page__lead">
          <div class="instructions landing-instructions bump-down">
            <span class="login-page__text">Select from below to log in</span>
            <button class="login-page__btn" (click)="playSelectLoginAudio()">
              <img src="assets/icons/text-to-speech.svg" alt="text to speech icon" aria-hidden="true" height="32"
                width="32">
            </button>
          </div>
        </div>
        <div class="login-page__focus">
          <div class="login-selection">
            <button class="login-selection__btn" (click)="stepForward()" trackClick="FHPStuLogin">
              <img class="login-selection__image" src="assets/funhub-button-thumbnail.svg" alt="FUN HUB Practice login"
                aria-hidden="true" />
              <span class="login-selection__text">Log in FUN HUB Practice</span>
            </button>
            <button class="login-selection__btn" (click)="loginClever()" trackClick="FHPStuCleverLogin">
              <img class="login-selection__image" src="assets/clever-new-image.svg" alt="clever login button"
                aria-hidden="true" />
              <span class="login-selection__text">Log in with Clever</span>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="loginSteps.TEACHER_PASSCODE">
        <div class="login-page__lead">
          <div class="hero">
            <img class="hero__image" src="assets/fhp-hero-image.svg" alt="FUN HUB Practice characters banner" />
          </div>
        </div>
        <div class="login-page__focus">
          <div class="instructions">
            <span>Enter Your Teacher's Passcode</span>
            <button class="login-page__btn" (click)="playTeacherPasscodeAudio()">
              <img src="assets/icons/text-to-speech.svg" alt="text to speech icon" aria-hidden="true" height="32"
                width="32">
            </button>
          </div>
          <div class="flex flex-row align-items-center">
            <app-login-form #passcodeComponent [length]="6" [loading]="isLoading"
              (inputSubmit)="submitTeacherPasscode($event)" (triggerHelp)="showHelpDialog = true"></app-login-form>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="loginSteps.INITIALS">
        <div class="login-page__lead">
          <div class="teacher-header">
            <div class="teacher-header__avatar">{{ teacher.teacherPasscode }}</div>
            <div class="teacher-header__name">{{ teacher.teacherName }}</div>
            <div class="teacher-header__change-btn login-button">
              <button class="btn change-teacher" (click)="stepBack()" trackClick="FHPStuChangeTeacher">Change
                Teacher</button>
              <button class="login-page__btn" (click)="playChangeTeacherAudio()">
                <img src="assets/icons/text-to-speech.svg" alt="text to speech icon" aria-hidden="true" height="32"
                  width="32">
              </button>
            </div>
          </div>
        </div>
        <div class="login-page__focus">
          <div class="instructions">
            <span>Enter Your Initials</span>
            <button class="login-page__btn" (click)="playEnterInitialAudio()">
              <img src="assets/icons/text-to-speech.svg" alt="text to speech icon" aria-hidden="true" height="32"
                width="32">
            </button>
          </div>
          <div class="flex flex-row align-items-center">
            <app-login-form [length]="2" [loading]="isLoading" (inputSubmit)="submitStudentInitials($event)"
              (triggerHelp)="showHelpDialog = true"></app-login-form>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="loginSteps.STUDENT_PASSCODE">
        <div class="login-page__lead">
          <div class="student-header">
            <div class="student-header__avatar student">{{ studentInitials }}</div>
            <div class="student-header__change-btn login-button">
              <button class="btn change-student" (click)="stepBack()" trackClick="FHPStuChangeStudent">Change
                Student</button>
              <button class="login-page__btn" (click)="playChangeStudentAudio()">
                <img src="assets/icons/text-to-speech.svg" alt="text to speech icon" aria-hidden="true" height="32"
                  width="32">
              </button>
            </div>
          </div>
        </div>
        <div class="login-page__focus">
          <div class="instructions">
            <span>Student Password</span>
            <button class="login-page__btn" (click)="playStudentPasswordAudio()">
              <img src="assets/icons/text-to-speech.svg" alt="text to speech icon" aria-hidden="true" height="32"
                width="32">
            </button>
          </div>
          <app-icon-password [iconList]="parentIconList" [loading]="isLoading"
            (iconSelected)="submitStudentIconPasscode($event.id)"></app-icon-password>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- help dialog -->
<p-dialog cdkTrapFocus="true" [style]="{ width: '30rem' }" [modal]="true" [(visible)]="showHelpDialog"
  [focusOnShow]="true" [draggable]="false" [closable]="false" class="align-content-center login-dialog">
  <ng-template pTemplate="header">
    <div class="login-dialog__header">Need help?</div>
  </ng-template>
  <div class="login-dialog__body">
    <p><strong>Student:</strong> Check with your teacher or parent for the passcode</p>
    <p>
      <strong>Teacher:</strong> To login to your portal, go through
      <a href="https://funhub.com" target="_blank">funhub.com</a>
      and click the Teacher + Admin login button
    </p>
    <p><strong>Parent:</strong> Check with teacher to receive the passcode</p>
  </div>
  <div class="login-dialog__footer">
    <div class="login-button">
      <button class="btn" (click)="closeHelpDialog()">Close</button>
    </div>
    <div class="speaker" (click)="playHelpAudio()">
      <img *ngIf="isSoundPlaying$ | async" src="assets/text-to-speech-inactive.svg" alt="speaker inactive button"
        class="speaker-inactive" />
      <img *ngIf="(isSoundPlaying$ | async) === false" src="assets/text-to-speech-active.svg"
        alt="speaker active button" class="speaker-active" />
    </div>
  </div>
</p-dialog>

<!-- incorrect teacher passcode/initials/password dialog -->
<p-dialog cdkTrapFocus="true" [style]="{ width: '30rem' }" [modal]="true" [(visible)]="showIncorrectDialog"
  [focusOnShow]="true" [draggable]="false" [closable]="false" class="align-content-center text-center login-dialog"
  #dialog>
  <ng-template pTemplate="header">
    <div class="login-dialog__header">
      <img ngSrc="assets/icons/warning-exclamation-circle.svg" alt="Incorrect Passcode" width="40" height="40" />
      <span>Incorrect {{ incorrectDialogHeader }}</span>
    </div>
  </ng-template>
  <div class="login-dialog__body">
    <p>Please find a teacher or adult who can help you.</p>
  </div>
  <div class="login-dialog__footer">
    <div class="login-button">
      <button class="btn" (click)="closeIncorrectDialog()" trackClick="FHPStuWrongPassword">Close</button>
    </div>
    <div class="speaker">
      <img *ngIf="isSoundPlaying$ | async" src="assets/text-to-speech-inactive.svg" alt="speaker inactive button"
        class="speaker-inactive" />
      <img *ngIf="(isSoundPlaying$ | async) === false" (click)="playIncorrectInput()"
        src="assets/text-to-speech-active.svg" alt="speaker active button" class="speaker-active" />
    </div>
  </div>
</p-dialog>

<!-- invalid Clever student login dialog -->
<p-dialog cdkTrapFocus="true" [style]="{ width: '30rem' }" [modal]="true" [(visible)]="showInvalidCleverLoginDialog"
  [focusOnShow]="true" [draggable]="false" [closable]="true" (onHide)="closeCleverErrorDialog()"
  class="align-content-center text-center">
  <ng-template pTemplate="header">
    <div class="login-dialog__header">
      <img ngSrc="assets/icons/warning-exclamation-circle.svg" alt="Invalid Clever Login" width="40" height="40" />
      <span>Wilson Language Training account not linked to Clever</span>
    </div>
  </ng-template>
  <div class="login-dialog__body">
    <p>Please log in with your password or find a teacher or an adult who can help you.</p>
  </div>
  <div class="login-dialog__footer">
    <div class="login-button">
      <button class="btn" (click)="closeCleverErrorDialog(true)">Log in</button>
    </div>
    <div class="speaker">
      <img *ngIf="isSoundPlaying$ | async" src="assets/text-to-speech-active.svg" alt="speaker active button"
        class="speaker-active" />
      <img *ngIf="(isSoundPlaying$ | async) === false" (click)="playCleverErrorAudio()"
        src="assets/text-to-speech-inactive.svg" alt="speaker inactive button" class="speaker-inactive" />
    </div>
  </div>
</p-dialog>