<div
  id="choice-bank"
  class="grid grid-nogutter"
  [ngClass]="{
    maxWidth: choices.length > 12,
    minWidth: choices.length <= 12,
    hasGroup: containsGroup
  }"
>
  <div
    *ngFor="let choice of choices"
    class="choice"
    [ngClass]="{
      'col-custom': choices.length > 12,
      'col-2': choices.length <= 12
    }"
  >
    <app-letter-panel-component [letterPanel]="choice" (click)="onLetterChoiceClicked(choice)"> </app-letter-panel-component>
  </div>
</div>
