import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild, inject, DestroyRef } from '@angular/core';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { Observable, Subscription } from 'rxjs';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IAudioError } from '@app/shared/services/error-dialog.service';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { SYLLABLE_SEPARATOR } from '@app/shared/constants/activity-constants';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { DELAY } from '@app/shared/constants/delay-enums';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';

@Component({
  selector: 'app-audio-sidebar',
  standalone: true,
  imports: [CommonModule, SidebarModule, ButtonModule, ClipboardModule, ToggleButtonModule, FormsModule],
  templateUrl: './audio-sidebar.component.html',
  styleUrls: ['./audio-sidebar.component.scss'],
})
export class AudioSidebarComponent implements OnInit {
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;

  protected appEvent$: Observable<IAppEvent>;
  protected content: IAudioError;
  protected callback: () => void;
  protected tempData: string;
  protected copyData: string = '';
  protected destroyRef = inject(DestroyRef);
  protected audioFiles: any = [];
  protected sidebarVisible = false;
  protected buttonVisible = false;
  protected showExp = true;

  private _appStateService = inject(AppStateService);
  private _destroyRef = inject(DestroyRef);
  private _activityService = inject(ActivitySessionService);

  protected messages: IAudioError[] = [];

  constructor() { }

  ngOnInit(): void {
    this.appEvent$ = this._appStateService.appEvent$;

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.AUDIO_ERROR && event.message && event.callback) {
        const arrM = event?.message.split(SYLLABLE_SEPARATOR);
        const info: IAudioError = {
          filename: arrM[0],
          expression: arrM[1],
          callback: () => { // Crystal need this ?
            /*if (event.callback) {
              event.callback;
            }*/
          },
        };
        if (!this.messages.find((obj) => obj.filename === info.filename)) {
          this.messages.unshift(info);
          this.copyData += `Expression: ${this.messages[0]['expression']} Filename: ${this.messages[0]['filename']},\n`;
        } else {
          let tempIdx = 0;
          this.messages.find((obj, index) => {
            if (obj.filename === info.filename) {
              tempIdx = index;
            }
          });
          this.messages.splice(tempIdx, 1);
          this.messages.unshift(info);
        }
        this.sidebarVisible = true;
        this.showExp = true;
        this._appStateService.appEvent$.next({ area: APP_EVENT_AREAS.VIEW_EXPRESSION, message: this.messages[0]['expression'], callback: event.callback });
      }
      if (event.area !== APP_EVENT_AREAS.AUDIO_ERROR) {
        this.showExp = false;
      }
    });
  }

  onDialogHidden(callback?: () => void | undefined): void {
    if (callback) {
      callback();
    }
  }

  closeSideBar(e): void {
    this.sidebarRef.close(e);
  }

  invokeCallback(event: any) {
    // TODO this._activityService.setExpressViewer(this.showExp);
  }

  @HostListener('document:keydown', ['$event'])
  clickKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.key === 'Z' && this.sidebarVisible === false) {
      this.sidebarVisible = true;
    } else {
      if (event.ctrlKey && event.shiftKey && event.key === 'Z' && this.sidebarVisible === true) {
        this.sidebarVisible = false;
      }
    }
  }
}
