<div id="wlt-activity" class="wlt-activity" *ngIf="this.activityReady">
  <div class="game-area">
    <ng-container id="wff">
      <!-- PuzzleType 1 - Word Carousel -->
      <ng-container *ngIf="puzzleType === puzzleTypeEnum.Word">
        <app-wff-puzzle-type-1
          [activitySolved]="activitySolved"
          (carouselCompleted)="onCarouselCompleted()"
          [currentWordFindFunDataset]="currentWordFindFunDataset"
          [expressions]="expressions"
          (completedPuzzle)="onPuzzleComplete($event)"
          (carouselProceedChange)="carouselProceed = $event"
          [puzzleTypeEnum]="puzzleTypeEnum"
          [wordFindFunDatasets]="wordFindFunDatasets"
          (displayPB)="displayPB()"
        ></app-wff-puzzle-type-1>
      </ng-container>

      <!-- PuzzleType 0 - Marking -->
      <ng-container *ngIf="puzzleType === puzzleTypeEnum.Marking">
        <app-wff-puzzle-type-0
          [currentWordFindFunDataset]="currentWordFindFunDataset"
          [expressions]="expressions"
          (completedPuzzle)="onPuzzleComplete($event)"
          (displayPB)="displayPB()"
        ></app-wff-puzzle-type-0>
      </ng-container>
    </ng-container>
  </div>

  <div
    class="progress-bar-area"
    [ngClass]="{
      visible: !activitySolved && carouselProceed,
      hidden: !carouselProceed || activitySolved
    }"
  >
  <app-progress-bar
  [totalPuzzles]="totalPuzzles"  
  [answeredPuzzles]="answeredPuzzles">
  </app-progress-bar>
  </div>
</div>
