import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { IMakeAChangeDataset } from '../../types/make-a-change-dataset-interface';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { MISTAKE_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { COLON_SEPARATOR, SYLLABLE_SEPARATOR } from '@app/shared/constants/activity-constants';

@Component({
  selector: 'app-mac-puzzle-type-0',
  standalone: true,
  imports: [LetterPanelComponent, CommonModule, NgOptimizedImage],
  templateUrl: './puzzle-type-0.component.html',
  styleUrl: './puzzle-type-0.component.scss',
})
export class MACPuzzleType0Component extends PuzzleTypeBaseComponent {
  @Input() currentMacDataset: IMakeAChangeDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() newGroup: boolean;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();
  @Output() displayPB = new EventEmitter();
  @ViewChildren(LetterPanelComponent) activeLetters: QueryList<LetterPanelComponent>;

  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected soundIcon = `/assets/play-icon.svg`;

  private targetPanel: ILetterPanel;

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    if (!this.currentMacDataset?.expressions) {
      this.letterPanelsActiveWord.forEach((lp) => {
        lp.success = false;
        lp.active = false;
      });
      setTimeout(() => {
        this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
        AnimationHelpers.animate('active-word', ANIMATIONS.BackOutUp);
        setTimeout(() => {
          this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
        }, DELAY.S1)
      }, DELAY.S1)
      return;
    }

    if (this.newGroup) {
      this.startPuzzleMetrics();
    }

    this.resetTries();
    this.setExpressions(this.expressions);
    this.initActiveWord();
    this.setMaxTries(this.currentMacDataset?.maxTries)
    
    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => lp.isCorrectAnswer)[0];
    this.validatingPuzzle = false;

    this.soundService.playExpression(this.getExpression(1), () => {
     AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
    });

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: this.getExpression(1) || '',
    });
  }

  initActiveWord() {
    if (this.currentMacDataset) {
      AppHelpers.buildTargetWordPanels(this.currentMacDataset.word, this.letterPanelsActiveWord);
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }

    selectedPanel.active = true;

    this.validatingPuzzle = true;
    selectedPanel.active = true;


    this.soundService.playSound(SOUND_NAMES.Click, () => {
      selectedPanel.active = false;
      if (selectedPanel.isCorrectAnswer) {
        selectedPanel.success = true;
        this.soundService.playSound(SOUND_NAMES.Correct, () => {
          // clear mark
          this.soundService.playExpression(this.getExpression(3), () => {
            if (this.currentMacDataset) this.currentMacDataset.completed = true;
            const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
            this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
            this.subPartComplete.emit(false);
            setTimeout(() => {
              this.emitCall.emit();
            }, DELAY.S2)
          });
        });
        return;
      } else {
        selectedPanel.error = true;

        this.addUserMistakeDetails({
          content: selectedPanel.content,
          context: MISTAKE_CONTEXT.Wrong_Word,
        });

        AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

        if (this.hasTriesExceeded()) {
          this.soundService.playSound(SOUND_NAMES.Incorrect);
          this.addMistake();

          if (!this._selfCorrected) {
            this.subpartSelfCorrected(true);
          }

          const correctPanel = this.letterPanelsActiveWord.filter((lp) => lp.isCorrectAnswer)[0];

          this.soundService.playExpression(this.getExpression(5), () => {
            selectedPanel.error = false;
            selectedPanel.active = false;
            AnimationHelpers.animate(correctPanel?.id, ANIMATIONS.Tada);
            correctPanel.success = true;

            if (correctPanel) {
              setTimeout(() => {
                correctPanel.success = false;
                this.subPartComplete.emit(false);
                setTimeout(() => {
                  this.emitCall.emit();
                }, DELAY.S2)
              }, DELAY.S2);
            }
          });
        } else {
          this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
            this.soundService.playExpression(this.getExpression(4), () => {
              selectedPanel.error = false;
              selectedPanel.active = false;
              this.validatingPuzzle = false;
            });
          });
        }
      }
    });
  }
}
