import { Component, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppStateService } from '@app/shared/services/app-state.service';
import { AudioRecordingService } from '@app/shared/services/audio-recording.service';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-audio-visualizer',
  standalone: true,
  imports: [],
  templateUrl: './audio-visualizer.component.html',
  styleUrl: './audio-visualizer.component.scss'
})
export class AudioVisualizerComponent implements OnInit {
  @ViewChild('visual', {static: true}) canvasRef!: ElementRef<HTMLCanvasElement>;

  protected appEvent$: Observable<IAppEvent>;
  private _destroyRef = inject(DestroyRef);


  private _appStateService = inject(AppStateService);

  constructor(private audioRecordingService: AudioRecordingService){}

  ngOnInit(){
    this.appEvent$ = this._appStateService.appEvent$;
    this.detectAudio();
    this.defaultBars();
  }

  detectAudio(){
    this.audioRecordingService.audioData$
    .pipe(takeUntilDestroyed(this._destroyRef)) 
    .subscribe(dataArray =>{
      const canvas = this.canvasRef.nativeElement;
      const canvasCtx = canvas.getContext('2d')!;
      canvasCtx.clearRect(0,0,canvas.width, canvas.height)

      const padding = 4;
      const numBars= 35;
      const barWidth = 4;
      const totalBarWidth = numBars * barWidth + (numBars - 1) * 4;

      const x =(canvas.width - totalBarWidth) / 2; 
      const y = (canvas.height - (canvas.height - padding * 2)) / 2;

      for(let i = 0; i < numBars; i++){
        const barHeight = dataArray[i] / 255 * (canvas.height - padding * 2);
        canvasCtx.fillStyle = 'black';
        canvasCtx.fillRect(x + i * (barWidth * 2) , y + (canvas.height - barHeight)/2,barWidth, barHeight);
      }
    })
  }

  defaultBars(){
    const canvas = this.canvasRef.nativeElement;
    const canvasCtx = canvas.getContext('2d')!;

    if(!canvasCtx){
      return
    }

    const numBars= 35;
    const barWidth = 4;
    const barHeight = 50;

    const totalBarWidth = numBars * barWidth + (numBars - 1) * 4;

    const x = (canvas.width - totalBarWidth) / 2;
    const y = (canvas.height - barHeight) / 2;


    canvasCtx.fillStyle = 'black';

    for(let i = 0; i < numBars; i++){
      canvasCtx.fillRect(x + i * (barWidth * 2), y, barWidth, barHeight)
    }

  }
}
